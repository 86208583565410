<template>
  <div class="GlobalHotkeys" />
</template>
<script>
import { Notify, Dark, useQuasar } from 'quasar'
import { onMounted, onUnmounted } from 'vue'
import { useLocalStorage } from 'src/lib/storage.js'

export default {
  setup () {
    const localStorage = useLocalStorage()
    const quasar = useQuasar()
    let codes = []
    const handleHotkey = async (ev) => {
      // Toggle dark mode with F9, reset dark mode with Shift-F9
      if (ev.key === 'F9') {
        if (ev.shiftKey) {
          Dark.set('auto')
          Notify.create({
            message: 'Dark mode reset to OS settings.',
            timeout: 1
          })
        } else {
          if (quasar.dark.isActive) {
            Notify.create({
              message: 'Dark mode disabled.',
              timeout: 1
            })
          } else {
            Notify.create({
              message: 'Dark mode enabled.',
              timeout: 1
            })
          }
          Dark.set(!quasar.dark.isActive)
        }
      }
      if (ev.code === 'KeyR' && ev.altKey) {
        // Robinson Mode
        console.log('Robinson Mode')
        const robot = (await import(/* webpackChunkName: "robot" */'src/lib/robot.js')).robot
        await robot()
      }
      codes.push(ev.code)
      if (codes.length > 11) {
        codes = codes.slice(1)
      }
      // Check for konami code
      if (codes.join(',') === 'ArrowUp,ArrowUp,ArrowDown,ArrowDown,ArrowLeft,ArrowRight,ArrowLeft,ArrowRight,KeyB,KeyA,Escape') {
        console.log('Developer Mode', localStorage.developerMode = !localStorage.developerMode)
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', handleHotkey)
    })
    onUnmounted(() => {
      document.removeEventListener('keydown', handleHotkey)
    })
  }
}
</script>
